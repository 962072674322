import { ChangeEvent } from "react";

import styled from "styled-components";
import InputMask from "react-input-mask";
import TextField from "@flame-ui/core/textField";

const Container = styled.div`
  width: 360px;
  max-width: 460px;
`;

const InputCuit = ({
  searchClient,
  handleOnChange,
  error,
}: {
  searchClient: string;
  handleOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
}) => {
  return (
    <Container>
      {/* @ts-expect-error Server Component */}
      <InputMask
        mask="99-99999999-9"
        maskChar=""
        value={searchClient}
        onChange={handleOnChange}
      >
        {() => (
          <TextField
            data-testid="input-cuit"
            label="CUIT"
            error={error && "El número de CUIT debe contener 11 dígitos."}
          />
        )}
      </InputMask>
    </Container>
  );
};

export default InputCuit;
