import { FC } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { LoginPage } from "../pages/login/Login";
import Dashboard from "../pages/dashboard/Dashboard";
import Search from "../pages/evaluation/Search";

import PrivateRoute from "./PrivateRoute";
import LoginRoute from "./LoginRoute";
import Amount from "../pages/evaluation/Amount";
import Email from "../pages/evaluation/Email";
import Summary from "../pages/evaluation/Summary";
import Feedback from "../pages/feedback/Feedback";

export const AppRouter: FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginRoute />}>
          <Route path="" element={<LoginPage />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/search" element={<Search />} />
          <Route path="/amount" element={<Amount />} />
          <Route path="/email" element={<Email />} />
          <Route path="/summary" element={<Summary />} />
          <Route path="/feedback" element={<Feedback />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
