import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import sharedReducer from "./features/sharedSlice";
import evaluationsReducer from "./features/evaluationsSlice";

export const store = configureStore({
  reducer: {
    authReducer,
    sharedReducer,
    evaluationsReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
