import MoneyField from "@flame-ui/core/moneyField";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setIsDisabledButton,
  setShowAvailableAmount,
} from "../../../redux/features/sharedSlice";
import { setAmount } from "../../../redux/features/evaluationsSlice";
import styled from "styled-components";

const StyledInput = styled(MoneyField)`
  width: 360px;
`;

const InputAmount = () => {
  const dispatch = useAppDispatch();
  const { availableAmount } = useAppSelector(
    (state) => state.evaluationsReducer
  );

  const [amountValue, setAmountValue] = useState("");
  const [amountError, setAmountError] = useState("");

  const handleAmountValue = (amount: any) => {
    const amountNumber = Number(amount.target.value.split(".").join(""));
    if (amountNumber > 9999) {
      if (amountNumber <= availableAmount) {
        setAmountError("");
        dispatch(setIsDisabledButton(false));
        dispatch(setShowAvailableAmount(false));
      } else {
        dispatch(setShowAvailableAmount(true));
      }
      dispatch(setAmount(amountNumber.toString()));
    } else {
      setAmountError("Ingresá un importe mayor o igual a $10.000.");
      dispatch(setIsDisabledButton(true));
    }
    setAmountValue(amount.target.value);
  };

  return (
    <StyledInput
      maxLength={20}
      data-testid={"input-amount"}
      decimalScale={0}
      allowLeadingZeros={false}
      allowNegative={false}
      boxSize="default"
      error={amountError}
      label="Importe"
      value={amountValue}
      onChange={(val) => handleAmountValue(val)}
    />
  );
};

export default InputAmount;
