import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { StyledTextColor } from "../../components/SgrStyledComponents";
import { Box } from "@flame-ui/core";
import { Spacer } from "../../shared/components/spacer/Spacer";
import SummaryButtons from "../../components/evaluation/summary/SummaryButtons";
import { evaluationFormattedData } from "../../helpers/evaluation/evaluationFormattedData.helper";
import SummaryDetail from "../../components/evaluation/summary/SummaryDetail";
import Typography from "@flame-ui/core/typography";
import { useEffect } from "react";
import { setCurrentStep, setNextPage } from "../../redux/features/sharedSlice";
import { gtmSummaryScreen } from "../../setup/tagManager/gtmEvents";
import Spinner from "@flame-ui/core/spinner";

const Container = styled.div`
  margin-top: 32px;
`;
const StyledTypographyInfo = styled(Typography)`
  margin-top: 20px;
`;

const Summary = () => {
  const dispatch = useAppDispatch();
  const { currentEvaluation } = useAppSelector(
    (state) => state.evaluationsReducer
  );
  const { isLoading } = useAppSelector((state) => state.sharedReducer);

  useEffect(() => {
    gtmSummaryScreen(
      currentEvaluation.customer.taxPayerId,
      currentEvaluation.isANewCustomer
    );
    dispatch(setNextPage("/feedback"));
    dispatch(setCurrentStep(3));
  }, [
    dispatch,
    currentEvaluation.customer.taxPayerId,
    currentEvaluation.isANewCustomer,
  ]);
  return (
    <>
      {isLoading ? (
        <Spinner loading size={2} text="Enviando..." />
      ) : (
        <>
          <StyledTextColor size="titleL">
            Revisá que todo esté bien antes de enviar la evaluación
          </StyledTextColor>
          <Container>
            <Box col="1 / span 4">
              {evaluationFormattedData(currentEvaluation).map((ev) => (
                <SummaryDetail label={ev.label} value={ev.value} key={ev.id} />
              ))}
              <StyledTypographyInfo size="paragraphM">
                La aprobación del préstamo está sujeta a revisión de controles
                internos.
              </StyledTypographyInfo>
              <Spacer height={40} />
              <SummaryButtons />
            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default Summary;
