import { FC } from "react";

import { useReactTable } from "@tanstack/react-table";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/table-core";
import {
  Table,
  THead,
  HeaderCell,
  TBody,
  BodyCell,
} from "@flame-ui/core/table";

import { Offer } from "../../interfaces/offers.interface";
import { StyledTextColor } from "../SgrStyledComponents";

const columnHelper = createColumnHelper<Offer>();
const columns = [
  columnHelper.accessor("customer.businessName", {
    cell: (info) => (
      <StyledTextColor size="paragraphM">{info.getValue()}</StyledTextColor>
    ),
    header: "Razón social",
  }),
  columnHelper.accessor("customer.taxPayerId", {
    cell: (info) => (
      <StyledTextColor size="paragraphM">{info.getValue()}</StyledTextColor>
    ),
    header: "CUIT",
  }),
  columnHelper.accessor("createdAt", {
    cell: (info) => (
      <StyledTextColor size="paragraphM">{info.getValue()}</StyledTextColor>
    ),
    header: "Fecha de evaluación",
  }),
  columnHelper.accessor("amount", {
    cell: (info) => (
      <StyledTextColor size="paragraphM">{info.getValue()}</StyledTextColor>
    ),
    header: "Importe",
  }),
  columnHelper.accessor("customer.email", {
    cell: (info) => (
      <StyledTextColor size="paragraphM">{info.getValue()}</StyledTextColor>
    ),
    header: "Email",
  }),
];

interface Props {
  evaluations: Offer[];
}

export const EvaluationsTable: FC<Props> = ({
  evaluations: filteredCampaigns,
}) => {
  const table = useReactTable({
    data: filteredCampaigns,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  return (
    <Table>
      <THead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            <HeaderCell headerGroup={headerGroup} />
          </tr>
        ))}
      </THead>
      <TBody data={filteredCampaigns} style={{ backgroundColor: "white" }}>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            <BodyCell row={row} />
          </tr>
        ))}
      </TBody>
    </Table>
  );
};
