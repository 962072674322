import styled from "styled-components";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "@flame-ui/core/button";
import { Box, Grid } from "@flame-ui/core/layout";
import Spinner from "@flame-ui/core/spinner";
import Typography from "@flame-ui/core/typography";

import { Spacer } from "../../shared/components/spacer/Spacer";
import { InputFieldString } from "../../shared/components/InputFieldString/InputFieldString";
import { ButtonsContainer } from "../../shared/components/buttonsContainer/buttonsContainer";
import { loginUser } from "../../services/login/login.service";
import { encryptData } from "../../helpers/encryptData";
import {
  cleanAttempts,
  addAttempt,
  getAttemptsMessage,
  refreshHelper,
} from "../../helpers/login/login.helper";
import {
  setFeedback,
  setIsLoggedIn,
  setShowRecoverPassword,
  setShowResetPassword,
} from "../../redux/features/authSlice";
import { setIsLoading } from "../../redux/features/sharedSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Line } from "../SgrStyledComponents";
import {
  setCurrentEvaluationEmail,
  setCurrentEvaluationPartner,
  setCurrentEvaluationRole,
} from "../../redux/features/evaluationsSlice";

const SpacerWidth = styled.div`
  min-width: 2rem;
`;

const StyledForgotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginForm = () => {
  const { timerIdToRefresh } = useAppSelector((state) => state.authReducer);
  const { isLoading } = useAppSelector((state) => state.sharedReducer);
  const dispatch = useAppDispatch();

  const handleLogin = async (formValues: {
    username: string;
    password: string;
  }) => {
    dispatch(setIsLoading(true));

    const encryptedPwd = encryptData(formValues.password);
    const body = {
      username: formValues.username,
      password: encryptedPwd,
    };
    try {
      const response = await loginUser(body);

      if (response.error?.isError) {
        // TODO: Ver de no repetir esto siempre
        dispatch(
          setFeedback({
            message: {
              header: "Estamos trabajando para que puedas ingresar pronto",
              body: "Por favor, volvé más tarde. ",
            },
            isError: true,
          })
        );
      } else if (response.error) {
        const error = response.error.response.data.data;
        if (error.code === "login.must.change.password") {
          cleanAttempts();
          dispatch(setShowResetPassword(true));
          sessionStorage.setItem("profile", formValues.username);
        } else {
          if (error.code === "login.credentials.error") {
            const attempts = addAttempt(formValues.username);
            const message = getAttemptsMessage(attempts, error);
            dispatch(setFeedback({ ...message, isError: true }));
          } else {
            if (error.code === "login.platform.error") {
              dispatch(
                setFeedback({ message: { ...error.message }, isError: true })
              );
            } else {
              dispatch(
                setFeedback({
                  message: {
                    header:
                      "Estamos trabajando para que puedas ingresar pronto",
                    body: "Por favor, volvé más tarde. ",
                  },
                  isError: true,
                })
              );
            }
          }
        }
      } else {
        cleanAttempts();
        sessionStorage.setItem("token", JSON.stringify(response.data));
        const currentPartner = response.data.partner;
        sessionStorage.setItem(
          "taxPayerId",
          JSON.stringify(currentPartner.businessCode)
        );
        dispatch(
          setCurrentEvaluationPartner({
            taxPayerId: currentPartner.businessCode,
            customerId: currentPartner.businessCode,
            businessName: currentPartner.businessName,
          })
        );
        dispatch(setCurrentEvaluationEmail(response.data.email));
        dispatch(setCurrentEvaluationRole(response.data.partner.roles.code));
        dispatch(setIsLoggedIn(true));
        refreshHelper(dispatch, response.data, timerIdToRefresh);
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => {
          handleLogin(values);
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string()
            .required("Por favor, ingresá tu usuario.")
            .matches(/^[a-zA-Z]{3}\d{4}$/, "El formato ingresado es inválido."),
          password: Yup.string().required("Por favor, ingresá tu clave."),
        })}
      >
        {() => {
          return (
            <Form data-testid="login">
              <InputFieldString
                data-testid="input-user"
                label="Usuario"
                name="username"
                popoverInfo={true}
                popoverText="Recordá que tu usuario es de 7 caracteres entre letras y números"
              />
              <Spacer height={24} />

              <Grid gridCols={20} gridRows={1}>
                <Box colStart="1" colEnd="20">
                  {/* TODO: hay un componente PasswordField que podría usarse */}
                  <InputFieldString
                    data-testid="input-pass"
                    type="password"
                    label="Clave"
                    name="password"
                    visibilityButton={true}
                  />
                </Box>
                <Box colStart="20" colEnd="20">
                  <SpacerWidth />
                </Box>
              </Grid>
              <Spacer height={24} />
              {isLoading ? (
                <Spinner loading={isLoading} fullScreen={false} size={4} />
              ) : null}

              <Grid gridCols={20} gridRows={1}>
                <Box colStart="1" colEnd="20">
                  <ButtonsContainer>
                    <Button
                      className="expand"
                      variant="primary"
                      size="large"
                      type="submit"
                      disabled={isLoading}
                      data-testid="buttonIngresar"
                    >
                      Ingresar
                    </Button>
                  </ButtonsContainer>
                </Box>
                <Box colStart="20" colEnd="20">
                  <SpacerWidth />
                </Box>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Grid gridCols={20} gridRows={1}>
        <Box colStart="1" colEnd="20">
          <StyledForgotContainer>
            <Line />
            <Typography size="paragraphL">
              Si olvidaste tu clave y/o usuario
            </Typography>
            <Button
              variant="tertiary"
              onClick={() => {
                dispatch(setShowRecoverPassword(true));
              }}
              data-testid="recoverButton"
            >
              Recuperar usuario y/o clave
            </Button>
          </StyledForgotContainer>
        </Box>
        <Box colStart="20" colEnd="20">
          <SpacerWidth />
        </Box>
      </Grid>
    </>
  );
};

export default LoginForm;
