import { useEffect, useState } from "react";

import styled from "styled-components";
import Button from "@flame-ui/core/button";
import Spinner from "@flame-ui/core/spinner";
import Coachmark, { CoachStep } from "@flame-ui/core/coachmark";
import Santander from "@flame-ui/icons/src/santander";
import Book from "@flame-ui/icons/src/book";
import IconButton from "@flame-ui/core/iconButton";

import EmptyState from "../../components/dashboard/EmptyState";
import Card from "../../components/dashboard/Card";
import { EvaluationsTable } from "../../components/dashboard/EvaluationsTable";
import { Spacer } from "../../shared/components/spacer/Spacer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import { StyledTextColor } from "../../components/SgrStyledComponents";
import { useLatestEvaluations } from "../../hooks/useLatestEvaluations";
import { downloadExcelFile } from "../../helpers/dashboard/dashboard.helper";
import {
  setCurrentStep,
  setShowStepper,
} from "../../redux/features/sharedSlice";
import { gtmDashboardScreen } from "../../setup/tagManager/gtmEvents";

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TourContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Dashboard = () => {
  const [openTour, setOpenTour] = useState(
    JSON.parse(localStorage.getItem("showTour") || "true")
  );

  const handleOnCloseOrFinish = () => {
    localStorage.setItem("showTour", "false");
    setOpenTour(false);
  };

  const { history } = useAppSelector((state) => state.evaluationsReducer);
  const { isLoading } = useAppSelector((state) => state.sharedReducer);
  const dispatch = useAppDispatch();

  useLatestEvaluations();

  useEffect(() => {
    gtmDashboardScreen();
    dispatch(setShowStepper(false));
    dispatch(setCurrentStep(0));
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <Spinner loading size={2} text="Cargando..." />
      ) : (
        <>
          <TourContainer>
            <StyledTextColor size="titleXl">
              Te damos la bienvenida!
            </StyledTextColor>
            {history.length === 0 && (
              <IconButton
                icon={<Book />}
                onClick={() => setOpenTour(true)}
                text="Abrir tour"
                variant="accent"
              />
            )}
          </TourContainer>
          <Spacer height={16} />
          <Card />
          <Spacer height={32} />
          <div id="latest-evaluations">
            <TableHeader>
              <StyledTextColor size="titleL">
                Últimas evaluaciones
              </StyledTextColor>
              {history.length > 0 && (
                <Button
                  variant="accent"
                  size="medium"
                  onClick={downloadExcelFile}
                  data-testid="buttonDownload"
                >
                  Exportar a Excel
                </Button>
              )}
            </TableHeader>
            <Spacer height={16} />
            {history.length > 0 ? (
              <EvaluationsTable evaluations={history} />
            ) : (
              <EmptyState />
            )}
          </div>
          <Coachmark
            show={openTour && history.length === 0}
            onClose={handleOnCloseOrFinish}
            onFinish={handleOnCloseOrFinish}
            tourIntroModal={{
              title: "Todas tus financiaciones en un solo lugar",
              description:
                "Descubrí las características principales de la plataforma Santander Financiaciones",

              primaryText: "Comenzar",
              secondaryText: "Ver más tarde",
              image: <Santander size={3} color="red" />,
              reOpen: false,
            }}
          >
            <CoachStep
              title="Evaluá a tus clientes"
              elementId="evaluate-button"
              placement="bottomEnd"
            >
              Podes realizar evaluaciones para personas jurídicas, clientes o no
              clientes de Santander.
            </CoachStep>
            <CoachStep
              title="Últimas evaluaciones"
              elementId="latest-evaluations"
              placement="topEnd"
            >
              Vas a encontrar un listado de tus últimas 5 evaluaciones
              realizadas.
            </CoachStep>
          </Coachmark>
        </>
      )}
    </>
  );
};

export default Dashboard;
