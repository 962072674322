import { Evaluation } from "../../interfaces/offers.interface";
import { formatMoney } from "../formatters/formatMoney.helper";
import { formatCuit } from "../formatters/formatCuit.helper";
import { formatDate } from "../formatters/formatDate.helper";

export const evaluationFormattedData = (currentEvaluation: Evaluation) => {
  const { customer } = currentEvaluation;

  return [
    {
      id: "businessName",
      label: "Razón social",
      value: customer.businessName,
    },

    {
      id: "taxPayerId",
      label: "CUIT",
      value: formatCuit(customer.taxPayerId),
    },
    {
      id: "evaluationDate",
      label: "Fecha de evaluación",
      value: formatDate(new Date()),
    },
    {
      id: "amount",
      label: "Importe",
      value: formatMoney(currentEvaluation.amount),
    },
    {
      id: "email",
      label: "Email",
      value: customer.email,
    },
  ];
};
