import { fireToast } from "@flame-ui/core/toast";

import { exportOffers } from "../../services/offers/offers.service";
import { gtmDashboardExport } from "../../setup/tagManager/gtmEvents";

export const downloadExcelFile = async () => {
  gtmDashboardExport();
  const response = await exportOffers();

  if (response.error) {
    return fireToast({
      text: "Ocurrió un error al exportar el archivo.",
      variant: "error",
    });
  }

  // Create blob link to download
  const url = window.URL.createObjectURL(
    // eslint-disable-next-line no-undef
    new Blob([response])
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `Operaciones.xlsx`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode!.removeChild(link);
  fireToast({
    text: "El archivo se descargó con éxito.",
    variant: "success",
  });
};
