import { gtmTag } from "./gtmConfiguration";
import { multiplierTags } from "./multiplierTags";

// Generic events
const gtmPageView = (screen: string, params: { [key: string]: any }) =>
  gtmTag({
    signal: "screen_view",
    screen,
    ...params,
  });

const gtmEvent = (signal: string, params: { [key: string]: any }) =>
  gtmTag({
    tipo_signal: "interaccion",
    signal,
    ...params,
  });

const gtmErrorEvent = (params: { [key: string]: any }) =>
  gtmTag({
    tipo_signal: "errores",
    signal: "error-response",
    ...params,
  });

// Page views
export const gtmDashboardScreen = () =>
  gtmPageView("inicio", {
    prd_sub: "no_aplica",
    op: "consulta",
  });

export const gtmSearchScreen = () =>
  gtmPageView("buscar-cliente", {
    prd_sub: "sgr",
    op: "alta",
  });

export const gtmAmountScreen = (cuit: string, isANewCustomer: boolean) =>
  gtmPageView("cargar-monto", {
    prd_sub: "sgr",
    op: "alta",
    transaccion: multiplierTags(cuit),
    tipo_cliente: isANewCustomer ? "ncl" : "cli",
  });

export const gtmEmailScreen = (cuit: string, isANewCustomer: boolean) =>
  gtmPageView("cargar-mail", {
    prd_sub: "sgr",
    op: "alta",
    transaccion: multiplierTags(cuit),
    tipo_cliente: isANewCustomer ? "ncl" : "cli",
  });

export const gtmSummaryScreen = (cuit: string, isANewCustomer: boolean) =>
  gtmPageView("resumen", {
    prd_sub: "sgr",
    op: "alta",
    transaccion: multiplierTags(cuit),
    tipo_cliente: isANewCustomer ? "ncl" : "cli",
  });

export const gtmFeedbackScreen = (cuit: string, isANewCustomer: boolean) =>
  gtmPageView("listo", {
    prd_sub: "sgr",
    op: "alta",
    transaccion: multiplierTags(cuit),
    tipo_cliente: isANewCustomer ? "ncl" : "cli",
  });

// Events
export const gtmDashboardInstructions = () =>
  gtmEvent("ver-instrucciones", {
    prd_sub: "no_aplica",
    op: "consulta",
  });

export const gtmDashboardExport = () =>
  gtmEvent("exportar", {
    prd_sub: "no_aplica",
    op: "consulta",
  });

export const gtmSearchCUIT = (cuit: string) =>
  gtmEvent("buscar-cliente", {
    prd_sub: "sgr",
    op: "alta",
    transaccion: multiplierTags(cuit),
  });

export const gtmSetAmount = (
  cuit: string,
  monto_solicitado: string,
  monto_otorgado: number,
  isANewCustomer: boolean
) =>
  gtmEvent("cargar-monto", {
    prd_sub: "sgr",
    op: "alta",
    transaccion: multiplierTags(cuit),
    monto_otorgado,
    monto_solicitado,
    tipo_cliente: isANewCustomer ? "ncl" : "cli",
  });

// Errores
export const gtmFeedbackError = (
  cuit: string,
  feedback: {
    isError: boolean;
    code: string;
    header: string;
    body: string;
  },
  isANewCustomer: boolean
) =>
  gtmErrorEvent({
    prd_sub: "sgr",
    op: "alta",
    err: `${feedback.code} - ${feedback.header} - ${feedback.body}`,
    transaccion: multiplierTags(cuit),
    tipo_cliente: isANewCustomer ? "ncl" : "cli",
  });
