import { useContext } from "react";

import styled, { ThemeContext } from "styled-components";

import { Badge, StyledTextColor } from "../SgrStyledComponents";

const StepsContainer = styled.div`
  > * {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 8px;
    }
  }
`;

const Step = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const FlowSteps = ({ steps }: { steps: Array<string> }) => {
  const theme = useContext(ThemeContext);
  return (
    <StepsContainer data-testid="flow-steps">
      {steps.map((step, index: number) => (
        <Step key={index}>
          <Badge>
            <b>{index + 1}</b>
          </Badge>

          <StyledTextColor
            size="paragraphM"
            color={theme.colors.neutral["09"]}
            dangerouslySetInnerHTML={{ __html: step }}
          />
        </Step>
      ))}
    </StepsContainer>
  );
};
