import CSSReset from "@flame-ui/utils/css-reset";
import ThemeProvider from "@flame-ui/utils/theme-provider";
import peopleTheme from "@flame-ui/themes/people";
import Toast from "@flame-ui/core/toast";

import { ReduxProvider } from "./redux/provider";
import App from "./App";

export default function AppProviders() {
  return (
    <>
      {/* @ts-expect-error Server Component */}
      <CSSReset />
      <ReduxProvider>
        <ThemeProvider theme={peopleTheme}>
          <>
            <Toast />
            <App />
          </>
        </ThemeProvider>
      </ReduxProvider>
    </>
  );
}
