import currency from "currency.js";
export const formatMoney = (amount: number | string | undefined | null) => {
  if (!amount) {
    return "$ ";
  }
  return `${currency(amount, {
    separator: ".",
    decimal: ",",
    symbol: "$ ",
  }).format()}`;
};
