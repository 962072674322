import { Stepper, Step } from "@flame-ui/core/stepper";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setCurrentStep } from "../../../redux/features/sharedSlice";
import { useNavigate } from "react-router-dom";
import { pageToStep, stepsLabels } from "../../../constants/constants";

const StyledDiv = styled.div`
  margin-bottom: 24px;
  margin-top 24px;
  background-color: #fff;
  font-family: "Santander Micro Text", "Open Sans,sans-serif";
`;

const StyledStep = styled(Step)``;

export const StepperComponent = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { currentStep } = useAppSelector((state) => state.sharedReducer);

  const handleStep = (e: any) => {
    const stepNumber: number = Number(e.target.value);
    navigate(pageToStep[stepNumber]);
    dispatch(setCurrentStep(stepNumber));
  };
  return (
    <StyledDiv>
      <Stepper activeStep={currentStep}>
        {stepsLabels.map((step, index) => (
          <StyledStep
            data-testid={step}
            key={index}
            stepLabel={step}
            onClick={handleStep}
          />
        ))}
      </Stepper>
    </StyledDiv>
  );
};
