import TagManager from "react-gtm-module";
import { multiplierTags } from "./multiplierTags";

const tagManagerId = {
  gtmId: process.env.REACT_APP_GTM_ID!,
};

const basicDataLayerParams = {
  canal: "financiaciones",
  prd: "prestamos",
  event: "webSignals",
};

export const gtmInitialize = () => TagManager.initialize(tagManagerId);

export const gtmTag = (params: object | undefined) => {
  TagManager.dataLayer({
    dataLayer: {
      ...params,
      ...basicDataLayerParams,
      comercio: multiplierTags(
        JSON.parse(sessionStorage.getItem("taxPayerId")!)
      ),
    },
  });
};
