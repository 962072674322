import axios from "axios";
import {
  Evaluation,
  IRespOffers,
  IRespOffer,
} from "../../interfaces/offers.interface";

export const getOffers = async (): Promise<IRespOffers> =>
  axios
    .get(`/pjLoanOffer`)
    .then((res) => res.data)
    .catch((error) => ({ error }));

export const createOffer = async (req: Evaluation): Promise<IRespOffer> =>
  axios
    .post("/pjLoanOffer", req)
    .then((res) => res.data)
    .catch((error) => ({ error }));

export const exportOffers = async () =>
  axios
    .get("/pjLoanOffer/export", {
      method: "GET",
      responseType: "blob",
    })
    .then((res) => res.data)
    .catch((error) => ({ error }));
