import styled from "styled-components";
import FeedbackScreen from "../../components/feedback/FeedbackScreen";
import { Container } from "@flame-ui/core";
import { useAppDispatch } from "../../redux/hooks";
import { setShowStepper } from "../../redux/features/sharedSlice";

const StyledContainer = styled(Container)`
  min-height: 100vh;
`;
const Feedback = () => {
  const dispatch = useAppDispatch();
  dispatch(setShowStepper(false));

  return (
    <>
      <StyledContainer>
        <FeedbackScreen />
      </StyledContainer>
    </>
  );
};

export default Feedback;
