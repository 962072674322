import { Box } from "@flame-ui/core";
import styled from "styled-components";
import InputAmount from "../../components/evaluation/amount/InputAmount";
import NavigateButtons from "../../components/evaluation/navigateButtons/NavigateButtons";
import { Spacer } from "../../shared/components/spacer/Spacer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect } from "react";
import {
  setCurrentStep,
  setIsDisabledButton,
  setNextPage,
  setShowAvailableAmount,
} from "../../redux/features/sharedSlice";
import { StyledTextColor } from "../../components/SgrStyledComponents";
import Alert from "@flame-ui/core/alert";
import { formatMoney } from "../../helpers/formatters/formatMoney.helper";
import Typography from "@flame-ui/core/typography";
import { gtmAmountScreen } from "../../setup/tagManager/gtmEvents";

const Container = styled.div`
  max-width: 460px;
  margin-top: 32px;
`;

const StyledAvailableAlert = styled(Alert)`
  width: 700px;
  margin-bottom: 32px;
`;

const Amount = () => {
  const dispatch = useAppDispatch();
  const { showAvailableAmount } = useAppSelector(
    (state) => state.sharedReducer
  );
  const {
    availableAmount,
    currentEvaluation: { customer, isANewCustomer },
  } = useAppSelector((state) => state.evaluationsReducer);

  useEffect(() => {
    gtmAmountScreen(customer.taxPayerId, isANewCustomer);
    dispatch(setNextPage("/email"));
    dispatch(setCurrentStep(1));
    dispatch(setIsDisabledButton(true));
    dispatch(setShowAvailableAmount(false));
  }, [dispatch, customer.taxPayerId, isANewCustomer]);

  const setAvailableMessage = () => {
    return `<p>Tu cliente puede pedir hasta ${formatMoney(
      availableAmount
    )}. <strong>Si continuas con un importe mayor,<br>un oficial de cuenta analizará la evaluación y te contactará con una resolución.</strong></p>`;
  };

  return (
    <>
      <StyledTextColor size="titleL">
        ¿Cuánto dinero quiere pedir?
      </StyledTextColor>
      <Container>
        <Box col="1 / span 4">
          {showAvailableAmount && (
            <StyledAvailableAlert
              message={
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: setAvailableMessage(),
                  }}
                />
              }
              title=""
              variant="info"
            />
          )}
          <InputAmount />
          <Spacer height={40} />
          <NavigateButtons />
        </Box>
      </Container>
    </>
  );
};

export default Amount;
