import { useContext, useEffect, useState } from "react";

import styled, { ThemeContext } from "styled-components";
import Error from "@flame-ui/icons/src/error";
import CheckmarkInACircle from "@flame-ui/icons/src/checkmark-in-a-circle";
import Button from "@flame-ui/core/button";

import { Spacer } from "../../shared/components/spacer/Spacer";
import { ButtonsContainer } from "../../shared/components/buttonsContainer/buttonsContainer";
import { recoverPassword } from "../../services/login/login.service";
import { setFeedback, setRecoverEmail } from "../../redux/features/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Line, StyledTextColor } from "../SgrStyledComponents";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 72px;
`;
const StyledResendContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginFeedback = () => {
  let timer: string | number | NodeJS.Timeout | undefined;
  const seconds = 60;
  const [disabled, setDisabled] = useState(false);
  const { feedback, recoverEmail: email } = useAppSelector(
    (state) => state.authReducer
  );
  const dispatch = useAppDispatch();

  const theme = useContext(ThemeContext);

  useEffect(
    () => () => {
      dispatch(setRecoverEmail(null));
      clearTimeout(timer);
    },
    [dispatch, timer]
  );

  const handleClickCancel = () => {
    dispatch(setFeedback(null));
  };
  const handleClickResend = () => {
    setDisabled(true);
    recoverPassword({ email });
    timer = setTimeout(() => {
      setDisabled(false);
    }, seconds * 1000);
  };

  function getIcon() {
    if (feedback.isError) {
      return <Error color={theme.colors.support.error["03"]} size={4} />;
    } else {
      return (
        <CheckmarkInACircle
          color={theme.colors.support.success["04"]}
          size={4}
        />
      );
    }
  }

  function getButtons() {
    return (
      <ButtonsContainer>
        <Button
          variant="primary"
          size="large"
          onClick={handleClickCancel}
          data-testid="feedbackButton"
        >
          {feedback.textButton || "Entendido"}
        </Button>
      </ButtonsContainer>
    );
  }

  return (
    <>
      <Container>
        {getIcon()}
        <StyledTextColor size="titleM" color={theme.colors.neutral["09"]}>
          {feedback.message.header}
        </StyledTextColor>
        <StyledTextColor
          dangerouslySetInnerHTML={{ __html: feedback.message.body }}
          size="paragraphM"
          color={theme.colors.neutral["09"]}
          data-testid="feedbackTextBody"
        />

        <Spacer height={24} />
        {getButtons()}
        {email && (
          <>
            <Line />
            <StyledResendContainer>
              <StyledTextColor
                color={theme.colors.neutral["09"]}
                size="paragraphM"
              >
                Si no recibiste el email
              </StyledTextColor>
              <Button
                variant="tertiary"
                onClick={handleClickResend}
                disabled={disabled}
                data-testid="ResendButton"
              >
                Reenviar email
              </Button>
            </StyledResendContainer>
          </>
        )}
      </Container>
    </>
  );
};
