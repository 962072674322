import { useContext, useState } from "react";

import styled, { ThemeContext } from "styled-components";
import { useField } from "formik";
import TextField from "@flame-ui/core/textField";
import HelpInACircle from "@flame-ui/icons/src/help-in-a-circle";
import VisibilityOn from "@flame-ui/icons/src/visibility-on";
import VisibilityOff from "@flame-ui/icons/src/visibility-off";
import { Box, Grid } from "@flame-ui/core/layout";
import Popover from "@flame-ui/core/popover";
import IconButton from "@flame-ui/core/iconButton";

const StyledVisibilityButton = styled.div`
  position: absolute;
  right: 10px;
  top: 36px;
  margin: 0;
  cursor: pointer;
`;
const StyledInputContainer = styled.div`
  position: relative;
`;

const StyledBox = styled(Box)`
  position: relative;
  top: 32px;
`;

export const InputFieldString = ({
  label,
  visibilityButton,
  customError,
  popoverInfo,
  popoverText = "",
  autoComplete = "off",
  ...props
}: {
  label?: string;
  visibilityButton?: boolean;
  customError?: string;
  popoverInfo?: boolean;
  popoverText?: string;
  autoComplete?: string;
  name: string;
  type?: "text" | "password" | "email";
  helperText?: string;
}) => {
  const [field, meta] = useField(props);
  const theme = useContext(ThemeContext);
  const [inputTypePassword, setinputTypePassword] = useState<
    "text" | "password"
  >("password");
  const [open, setOpen] = useState(false);
  const [iconPassword, setIconPassword] = useState(
    <VisibilityOff
      color={theme.colors.accent["01"]}
      size={1.6}
      alt="Mostrar clave"
    />
  );
  const showPassword = () => {
    inputTypePassword === "password"
      ? setinputTypePassword("text")
      : setinputTypePassword("password");
    inputTypePassword === "password"
      ? setIconPassword(
          <VisibilityOn
            color={theme.colors.accent["01"]}
            size={1.6}
            alt="Ocultar clave"
          />
        )
      : setIconPassword(
          <VisibilityOff
            color={theme.colors.accent["01"]}
            size={1.6}
            alt="Mostrar clave"
          />
        );
  };

  return (
    <Grid gridCols={20} gridRows={1}>
      <Box colStart="1" colEnd={popoverInfo ? "20" : "21"}>
        <StyledInputContainer>
          <TextField
            label={label}
            role="input"
            {...field}
            {...props}
            error={meta.touched && meta.error ? meta.error : customError}
            type={visibilityButton ? inputTypePassword : props.type}
            autoComplete={autoComplete}
          />
          {visibilityButton && (
            <StyledVisibilityButton
              data-testid="visibilityPwdButton"
              role="button"
              onClick={() => {
                showPassword();
              }}
            >
              {iconPassword}
            </StyledVisibilityButton>
          )}
        </StyledInputContainer>
      </Box>
      {popoverInfo && (
        <StyledBox colStart="20" colEnd="20">
          <Popover
            onClose={() => setOpen(false)}
            title="Usuario"
            show={open}
            placement="topEnd"
            text={popoverText}
          >
            <IconButton
              icon={<HelpInACircle alt="help" />}
              size="small"
              type="button"
              variant="accent"
              onClick={() => setOpen(!open)}
            />
          </Popover>
        </StyledBox>
      )}
    </Grid>
  );
};
