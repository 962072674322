import styled, { ThemeContext } from "styled-components";
import Elevation from "@flame-ui/core/elevation";
import { SignOff } from "@flame-ui/icons";
import { Box, Grid } from "@flame-ui/core";
import logo from "../../assets/images/logoHeader.png";
import { useNavigate } from "react-router-dom";
import IconButton from "@flame-ui/core/src/buttons/iconButton/IconButton";
import { logoutHelper } from "../../helpers/login/login.helper";
import { useAppSelector } from "../../redux/hooks";
import { useDispatch } from "react-redux";
import { setIsLoggedIn } from "../../redux/features/authSlice";
import CircleAccountUser from "@flame-ui/icons/src/circle-account-user";
import { useContext } from "react";

const StyledElevation = styled(Elevation)`
  margin-bottom: 2rem;
  padding: 0.5rem;
  height: 64px;
  background-color: #fff;
  position: fixed;
  align-content: center;
  top: 0;
  width: 100%;
`;
const LogoSantander = styled.img`
  cursor: pointer;
  width: max-content;
  object-fit: contain;
  float: left;
  margin-left: 45px;
`;
const HeaderButtonsBox = styled(Box)`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
  right: 45px;
`;

const PartnerNameBox = styled(Box)`
  height: 2rem !important;
  min-height: 2rem !important;
  width: fit-content;
  min-width: 100;
  background: white;
  color: ${({ theme }) => theme.colors.main["01"]};
  border-radius: 40px;
  border: 1px solid ${({ theme }) => theme.colors.main["01"]};
  padding: 6px 12px 6px 12px;
  margin-left: 20px;
`;

const StyledIconButton = styled(IconButton)`
  height: 2rem;
  width: 2rem;
  margin-left: 20px;
  margin-right: -20px;
`;

const Header = () => {
  const theme = useContext(ThemeContext);
  const { timerIdToRefresh } = useAppSelector((state) => state.authReducer);
  const { currentEvaluation } = useAppSelector(
    (state) => state.evaluationsReducer
  );
  const businessName = currentEvaluation.additionalData.partner.businessName;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleHome = (e: any) => {
    e.preventDefault();
    navigate("/dashboard");
  };
  const handleLogout = (e: any) => {
    e.preventDefault();
    logoutHelper(timerIdToRefresh);
    dispatch(setIsLoggedIn(false));
  };
  return (
    <>
      <StyledElevation level={3}>
        <Grid
          gridCols={2}
          gridRows={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <LogoSantander
            src={logo}
            alt=""
            onClick={handleHome}
            data-testid="logo-button"
          />
          <HeaderButtonsBox>
            <CircleAccountUser color={theme.colors.main["01"]} size={2} />
            <PartnerNameBox>{businessName}</PartnerNameBox>
            <StyledIconButton
              data-testid="logout-button"
              icon={<SignOff size={1} />}
              onClick={handleLogout}
              variant="tertiary"
              size="medium"
            />
          </HeaderButtonsBox>
        </Grid>
      </StyledElevation>
    </>
  );
};

export default Header;
