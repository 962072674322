import styled from "styled-components";

import Typography from "@flame-ui/core/typography";

const defaultBadgetSize = 20;

export const StyledTextColor = styled(Typography)`
  color: ${({ theme, color }) => color || theme.colors.neutral["10"]};
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.neutral["04"]};
  margin: 24px 0 32px;
`;

export const Badge = styled.span<{
  bgColor?: string;
  color?: string;
  size?: number;
}>`
  background-color: ${({ theme, bgColor }) =>
    bgColor || theme.colors.neutral["06"]};
  color: ${({ theme, color }) => color || theme.colors.neutral["01"]};
  display: inline-block;
  min-width: ${({ size }) => `${(size || defaultBadgetSize).toString()}px`};
  height: ${({ size }) => `${(size || defaultBadgetSize).toString()}px`};
  border-radius: 50%;
  text-align: center;
  line-height: ${({ size }) => `${(size || defaultBadgetSize).toString()}px`};
  font-size: ${({ size }) =>
    `${((size || defaultBadgetSize) / 2).toString()}px`};
`;
