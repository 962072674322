export const pageToStep: any = {
  0: "/search",
  1: "/amount",
  2: "/email",
  3: "/summary",
};

export const stepsLabels = [
  "Datos de tu Cliente",
  "Importe a pedir",
  "Email",
  "Resumen de la evaluación",
];

export const mockCustomer = {
  customerId: "12345678",
  taxPayerId: "20317402215",
  personType: "J",
  businessName: "La Farola",
  emails: ["prueba@prueba.com"],
  costCenter: "230-000",
};
