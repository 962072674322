import { Grid } from "@flame-ui/core";
import Button from "@flame-ui/core/button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setCurrentStep } from "../../../redux/features/sharedSlice";
import { pageToStep } from "../../../constants/constants";

const NavigateButtons = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { nextPage, currentStep, isDisableButton } = useAppSelector(
    (state) => state.sharedReducer
  );

  const handleBack = () => {
    navigate(pageToStep[currentStep - 1]);
    dispatch(setCurrentStep(currentStep - 1));
  };
  const handleNext = () => {
    navigate(nextPage);
  };

  return (
    <Grid gridCols={12} gridRows={1} gap="20px">
      <Button
        size="medium"
        variant="secondary"
        onClick={handleBack}
        data-testid="btnBack"
      >
        Atrás
      </Button>
      <Button
        size="medium"
        disabled={isDisableButton}
        onClick={handleNext}
        data-testid="btnContinue"
      >
        Continuar
      </Button>
    </Grid>
  );
};

export default NavigateButtons;
