import Axios from "axios";

export const loginUser = async (encryptedData: {
  username: string;
  password: string;
}) =>
  Axios.post(`/loanAuthBe/login`, encryptedData)
    .then((res) => res.data)
    .catch((error) => ({ error }));

export const resetPassword = async (encryptedData: {
  username: string;
  password: string;
  newPassword: string;
}) =>
  Axios.post(`/loanAuthBe/reset-password`, encryptedData)
    .then((res) => res.data)
    .catch((error) => ({ error }));

export const recoverPassword = async (data: { email: string }) =>
  Axios.post(`/loanAuthBe/recover-password`, data)
    .then((res) => res.data)
    .catch((error) => ({ error }));

export const refreshSession = async (bodyRefresh: any) =>
  Axios.post(`/loanAuthBe/refresh`, bodyRefresh)
    .then((res) => res.data)
    .catch((error) => ({ error }));
