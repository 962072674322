import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Feedback } from "../../interfaces/offers.interface";

type SharedState = {
  isLoading: boolean;
  nextPage: string;
  currentStep: number;
  showStepper: boolean;
  isDisableButton: boolean;
  showInputEmail: boolean;
  feedback: {
    isError: boolean;
    code: string;
    header: string;
    body: string;
  };
  showAvailableAmount: boolean;
  searchTaxPayerId: string;
};

const initialState: SharedState = {
  isLoading: false,
  nextPage: "",
  currentStep: 0,
  showStepper: false,
  isDisableButton: true,
  showInputEmail: false,
  feedback: {
    isError: false,
    code: "success",
    header: "",
    body: "",
  },
  showAvailableAmount: false,
  searchTaxPayerId: "",
};

export const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setNextPage: (state, action: PayloadAction<string>) => {
      state.nextPage = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setShowStepper: (state, action: PayloadAction<boolean>) => {
      state.showStepper = action.payload;
    },
    setIsDisabledButton: (state, action: PayloadAction<boolean>) => {
      state.isDisableButton = action.payload;
    },
    setShowInputEmail: (state, action: PayloadAction<boolean>) => {
      state.showInputEmail = action.payload;
    },
    setFeedback: (state, action: PayloadAction<Feedback>) => {
      state.feedback = action.payload;
    },
    setShowAvailableAmount: (state, action: PayloadAction<boolean>) => {
      state.showAvailableAmount = action.payload;
    },
    setSearchTaxPayerId: (state, action: PayloadAction<string>) => {
      state.searchTaxPayerId = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setNextPage,
  setCurrentStep,
  setShowStepper,
  setIsDisabledButton,
  setShowInputEmail,
  setFeedback,
  setShowAvailableAmount,
  setSearchTaxPayerId,
} = sharedSlice.actions;

export default sharedSlice.reducer;
