import { refreshSession } from "../../services/login/login.service";
import {
  setOpenModalRefreshToken,
  setTimerIdToRefresh,
} from "../../redux/features/authSlice";

export const addAttempt = (username: string): number => {
  const attemptsString = localStorage.getItem("attempts");
  const attempts = attemptsString ? JSON.parse(attemptsString) : {};
  if (attempts[username]) {
    attempts[username] += 1;
  } else {
    attempts[username] = 1;
  }
  localStorage.setItem("attempts", JSON.stringify(attempts));
  return attempts[username];
};

export const getAttemptsMessage = (
  attempts: number,
  error: { code: string; message: { header: string; body: string } }
): { message: { header: string; body: string } } => {
  if (attempts === 1) {
    return error;
  } else if (attempts < 5) {
    return {
      ...error,
      message: {
        header: "Los datos son incorrectos",
        body: `<strong>Te quedan ${
          5 - attempts
        } intentos</strong> antes de que bloqueemos tu usuario.`,
      },
    };
  } else {
    return {
      ...error,
      message: {
        header: "Bloqueamos tu usuario por seguridad",
        body: "Por favor, <strong>esperá 5 minutos</strong> y volvé a intentarlo. ",
      },
    };
  }
};

export const cleanAttempts = () => {
  localStorage.removeItem("attempts");
};

export const refreshHelper = (
  dispatch: (arg0: any) => void,
  authTokens: { expiredAccess: any },
  timerIdToRefresh: any[]
) => {
  let { expiredAccess } = authTokens;

  let counter = 0;
  let max = 2;
  const expirationDate = (expiredAccess - 62) * 1000;
  // TODO: Para probar. Luego borrar
  //const expirationDate = 10 * 1000;

  const timer = setInterval(async () => {
    if (counter < max) {
      counter++;
      const tokenString = sessionStorage.getItem("token");
      // TODO: Lanzar error cuando sea null
      const { refreshToken } = tokenString
        ? JSON.parse(tokenString)
        : { refreshToken: null };

      const bodyRefresh = {
        refreshToken,
      };
      const responseRefreshUser = await refreshSession(bodyRefresh);
      sessionStorage.setItem("token", JSON.stringify(responseRefreshUser.data));
    } else {
      dispatch(setOpenModalRefreshToken(true));
      clearInterval(timer);
    }
  }, expirationDate);

  dispatch(setTimerIdToRefresh([...timerIdToRefresh, timer]));
};

export const logoutHelper = (timerIdToRefresh: any) => {
  sessionStorage.clear();
  timerIdToRefresh.forEach((timer: any) => clearInterval(timer));
};
