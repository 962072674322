/* eslint-disable react-hooks/exhaustive-deps */
import TextField from "@flame-ui/core/textField";
import { useAppDispatch } from "../../../redux/hooks";
import { setEmailCustomer } from "../../../redux/features/evaluationsSlice";
import { useEffect, useState } from "react";
import { setIsDisabledButton } from "../../../redux/features/sharedSlice";
import styled from "styled-components";

const StyledInput = styled(TextField)`
  width: 360px;
`;

const InputEmail = () => {
  const dispatch = useAppDispatch();

  const [emailValue, setEmailValue] = useState("");
  const [error, setError] = useState("");

  const handleEmail = (e: any) => {
    setEmailValue(e.target.value);

    if (isValidEmail(e.target.value)) {
      dispatch(setIsDisabledButton(false));

      setError("");
      dispatch(setEmailCustomer(e.target.value));
    } else {
      dispatch(setIsDisabledButton(true));
      setError("Ingresá un email válido");
    }
  };
  const isValidEmail = (val: string) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

  useEffect(() => {
    dispatch(setIsDisabledButton(true));
  }, [dispatch]);

  return (
    <StyledInput
      data-testid={`input-email`}
      error={error}
      value={emailValue}
      helperText="Ingresá un email"
      label="Email"
      type="email"
      onChange={(value: any) => handleEmail(value)}
    />
  );
};

export default InputEmail;
