import axios from "axios";
import {
  IReqControls,
  IRespClient,
  IRespControls,
} from "../../interfaces/customers.interface";

// VER SI SE VA A NECESITAR EL LLAMADO A CUSTOMER
export const getDataBaseClient = (
  producerTaxPayerId: string
): Promise<IRespClient> =>
  axios
    .get(`/customers/${producerTaxPayerId}`)
    .then((res) => res.data)
    .catch((error) => ({ error }));

export const postPreviousLoanControlsService = async (
  body: IReqControls
): Promise<IRespControls> =>
  axios
    .post("/customers/controls", body)
    .then((res) => res.data)
    .catch((error) => ({ error }));
