import { useState } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "@flame-ui/core/button";
import Modal from "@flame-ui/core/modal";

import { FlowSteps } from "../dashboard/FlowSteps";
import { StyledTextColor } from "../SgrStyledComponents";
import { gtmDashboardInstructions } from "../../setup/tagManager/gtmEvents";

const GET_LOAN_STEPS = [
  "Ingresá el <strong>número de CUIT de tu cliente.</strong>",
  "Ingresá el <strong>importe del préstamo</strong> que quiere pedir.",
  "Seleccioná el <strong>email de tu cliente</strong> para que reciba la evaluación.",
  "<strong>¡Listo!</strong> Un oficial de cuenta te contactará para avanzar con la acreditación del préstamo.",
];

const Container = styled.div`
  width: 100%;
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.neutral["00"]};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Card = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  return (
    <>
      <Container>
        <div>
          <StyledTextColor size="titleM">
            Realizá una evaluación crediticia para tu cliente
          </StyledTextColor>
          <Button
            variant="accent"
            size="small"
            onClick={() => {
              gtmDashboardInstructions();
              setShow(true);
            }}
          >
            Cómo realizar una evaluación
          </Button>
        </div>

        <Button
          size="medium"
          onClick={() => navigate("/search")}
          id="evaluate-button"
        >
          Evaluar
        </Button>
      </Container>
      <Modal
        show={show}
        onClose={() => setShow(false)}
        title="¿Cómo realizar una evaluación crediticia?"
      >
        <FlowSteps steps={GET_LOAN_STEPS} />
      </Modal>
    </>
  );
};

export default Card;
