import { Navigate, Outlet } from "react-router-dom";
import styled from "styled-components";

import { useAppSelector } from "../redux/hooks";
import { StepperComponent } from "../components/evaluation/stepper/StepperComponent";
import BreadCrumb from "../components/evaluation/breadcrum/BreadCrum";
import Header from "../components/header/Header";

const Container = styled.div`
  max-width: 1024px;
  margin: 120px auto;

  @media (max-width: 1024px) {
    margin: 120px 48px;
  }
`;
const PrivateRoute = () => {
  const { isLoggedIn } = useAppSelector((state) => state.authReducer);
  const { showStepper } = useAppSelector((state) => state.sharedReducer);

  return isLoggedIn ? (
    <>
      {<Header />}
      <Container>
        {showStepper && <BreadCrumb />}
        {showStepper && <StepperComponent />}
        <Outlet />
      </Container>
    </>
  ) : (
    <Navigate to={"/login"} replace />
  );
};

export default PrivateRoute;
