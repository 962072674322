import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "@flame-ui/core/button";
import Spinner from "@flame-ui/core/spinner";

import { ButtonsContainer } from "../../shared/components/buttonsContainer/buttonsContainer";
import { InputFieldString } from "../../shared/components/InputFieldString/InputFieldString";
import { Spacer } from "../../shared/components/spacer/Spacer";
import { recoverPassword } from "../../services/login/login.service";
import {
  setFeedback,
  setRecoverEmail,
  setShowRecoverPassword,
} from "../../redux/features/authSlice";
import { setIsLoading } from "../../redux/features/sharedSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

const RecoverPassword = () => {
  const isLoading = useAppSelector((state) => state.sharedReducer.isLoading);
  const dispatch = useAppDispatch();

  const handleRecoverPassword = async ({ email }: { email: string }) => {
    dispatch(setIsLoading(true));

    try {
      const response = await recoverPassword({ email });

      if (response.error?.isError) {
        // TODO: Ver de no repetir esto siempre
        dispatch(
          setFeedback({
            message: {
              header: "Estamos trabajando para que puedas ingresar pronto",
              body: "Por favor, volvé más tarde. ",
            },
            isError: true,
          })
        );
      } else if (response.error) {
        const error = response.error.response.data.data;
        dispatch(setFeedback({ ...error, isError: true }));
      } else {
        dispatch(
          setFeedback({
            message: {
              header: "Te enviamos tu usuario y una nueva clave temporal",
              body: "Por favor, verificá que hayas recibido este email para volver a ingresar a la plataforma.",
            },
          })
        );
        dispatch(setRecoverEmail(email));
        dispatch(setShowRecoverPassword(false));
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={(values) => {
        handleRecoverPassword(values);
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Ingresá un email válido")
          .required("Por favor, ingresá tu email."),
      })}
    >
      {() => {
        return (
          <Form noValidate>
            <InputFieldString
              type="email"
              label="Email"
              name="email"
              data-testid="input-email"
            />
            <Spacer height={24} />
            {isLoading ? (
              <Spinner loading={isLoading} fullScreen={false} size={4} />
            ) : null}
            <ButtonsContainer>
              <Button
                variant="primary"
                size="large"
                type="submit"
                disabled={isLoading}
                data-testid="recoverButton"
              >
                Recuperar usuario y clave
              </Button>
              <Button
                data-testid="backButton"
                size="large"
                onClick={() => dispatch(setShowRecoverPassword(false))}
                variant="secondary"
              >
                Atrás
              </Button>
            </ButtonsContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RecoverPassword;
