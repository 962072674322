import { useState } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "@flame-ui/core/button";
import Spinner from "@flame-ui/core/spinner";

import { InputFieldString } from "../../shared/components/InputFieldString/InputFieldString";
import { ButtonsContainer } from "../../shared/components/buttonsContainer/buttonsContainer";
import { Spacer } from "../../shared/components/spacer/Spacer";
import { resetPassword } from "../../services/login/login.service";
import { encryptData } from "../../helpers/encryptData";
import {
  setFeedback,
  setShowResetPassword,
} from "../../redux/features/authSlice";
import { setIsLoading } from "../../redux/features/sharedSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

const ResetPassword = () => {
  const [errorResetPassword, setErrorResetPassword] = useState("");

  const isLoading = useAppSelector((state) => state.sharedReducer.isLoading);
  const dispatch = useAppDispatch();

  const handleResetPassword = async (formValues: {
    password: string;
    confirmationPassword: string;
    newPassword: string;
  }) => {
    dispatch(setIsLoading(true));

    // TODO: Lanzar error y no devolver ""
    const username = sessionStorage.getItem("profile") || "";
    const { newPassword, password } = formValues;

    const encryptedPwd = encryptData(password);
    const encryptedNewPwd = encryptData(newPassword);
    const body = {
      username,
      password: encryptedPwd,
      newPassword: encryptedNewPwd,
    };
    try {
      const response = await resetPassword(body);

      if (response.error?.isError) {
        // TODO: Ver de no repetir esto siempre
        dispatch(
          setFeedback({
            message: {
              header: "Estamos trabajando para que puedas ingresar pronto",
              body: "Por favor, volvé más tarde. ",
            },
            isError: true,
          })
        );
      } else if (response.error) {
        setErrorResetPassword("Revisá que la clave ingresada sea correcta.");
      } else {
        dispatch(
          setFeedback({
            message: {
              header: "Cambiaste tu clave",
              body: "Por favor, ingresá con tu nueva clave.",
            },
            textButton: "Ingresar",
          })
        );
        setErrorResetPassword("");
        dispatch(setShowResetPassword(false));
      }
    } catch (error) {
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  return (
    <Formik
      initialValues={{
        password: "",
        confirmationPassword: "",
        newPassword: "",
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        handleResetPassword(values);
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required("Por favor, ingresá tu clave"),
        newPassword: Yup.string()
          .required("Por favor, ingresá tu nueva clave")
          .matches(
            new RegExp(
              /* eslint-disable-next-line */
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
            ),
            "Revisá que tenga 8 caracteres o más, una mayúscula, un número y un símbolo."
          ),
        confirmationPassword: Yup.string()
          .oneOf(
            [Yup.ref("newPassword"), null],
            "Debe coincidir con la clave que ingresaste arriba"
          )
          .required("Por favor, confirmá tu nueva clave"),
      })}
    >
      {() => {
        return (
          <Form>
            <InputFieldString
              type="password"
              label="Clave actual"
              name="password"
              visibilityButton={true}
              customError={errorResetPassword}
              data-testid="input-pass"
            />
            <Spacer height={24} />

            <InputFieldString
              type="password"
              label="Nueva clave"
              name="newPassword"
              visibilityButton={true}
              helperText="Debe tener 8 caracteres o más e incluir una mayúscula, 
                  un número y un símbolo."
              data-testid="input-new-pass"
            />
            <Spacer height={24} />

            <InputFieldString
              type="password"
              label="Repetir nueva clave"
              name="confirmationPassword"
              visibilityButton={true}
              data-testid="input-confirm-new-pass"
            />
            <Spacer height={24} />
            {isLoading ? (
              <Spinner loading={isLoading} fullScreen={false} size={4} />
            ) : null}
            <ButtonsContainer>
              <Button
                className="expand"
                variant="primary"
                size="large"
                type="submit"
                disabled={isLoading}
                data-testid="submitButton"
              >
                Cambiar clave
              </Button>
              <Button
                className="expand"
                data-testid="CancelButton"
                size="large"
                onClick={() => dispatch(setShowResetPassword(false))}
                variant="secondary"
              >
                Cancelar
              </Button>
            </ButtonsContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ResetPassword;
