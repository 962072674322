import { Grid } from "@flame-ui/core";
import Typography from "@flame-ui/core/typography";
import styled from "styled-components";

const RowLine = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px ${({ theme }) => theme.colors.neutral["04"]};
  padding: 0.7rem;
`;
const ContentInfo = styled.div`
  display: contents;
  -webkit-box-align: center;
`;

const SummaryDetail = (data: { label: string; value: string | undefined }) => {
  return (
    <RowLine>
      <ContentInfo>
        <Typography>{data.label}</Typography>
        <Typography bold>{data.value}</Typography>
      </ContentInfo>
    </RowLine>
  );
};

export default SummaryDetail;
