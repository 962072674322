import styled, { keyframes, useTheme } from "styled-components";

import { StyledTextColor } from "../../../components/SgrStyledComponents";
import { Spacer } from "../spacer/Spacer";
import progress from "../../../assets/images/Progress.png";

const indeterminateAnimation = keyframes`
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProgressBar = styled.div`
  height: 4px;
  background-color: ${({ theme }) => theme.colors.neutral["04"]};
  width: 433px;
  overflow: hidden;
`;

const ProgressBarValue = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.main["01"]};
  animation: ${indeterminateAnimation} 2s infinite linear;
  transform-origin: 0% 50%;
`;

const Loader = () => {
  const theme = useTheme();
  return (
    <Container>
      <img height={125.5} width={256} src={progress} alt="loader"></img>
      <Spacer height={16} />
      <ProgressBar>
        <ProgressBarValue />
      </ProgressBar>
      <Spacer height={16} />
      <StyledTextColor size="titleM" bold>
        Estamos buscando a tu cliente
      </StyledTextColor>
      <Spacer height={8} />
      <StyledTextColor color={theme.colors.neutral["09"]}>
        Esto tardará menos de 30 segundos.
      </StyledTextColor>
    </Container>
  );
};

export default Loader;
