import { ChangeEvent, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import Button from "@flame-ui/core/button";

import InputCuit from "../../components/evaluation/search/InputCuit";
import { StyledTextColor } from "../../components/SgrStyledComponents";
import { Spacer } from "../../shared/components/spacer/Spacer";
import { postPreviousLoanControlsService } from "../../services/customers/customers.service";
import {
  setCurrentEvaluationAvailableId,
  setCurrentEvaluationCustomer,
  setEmails,
  setAvailableAmount,
  setIsANewCustomer,
} from "../../redux/features/evaluationsSlice";
import {
  setCurrentStep,
  setFeedback,
  setIsLoading,
  setSearchTaxPayerId,
  setShowStepper,
} from "../../redux/features/sharedSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Loader from "../../shared/components/loader/Loader";
import { errorCleaner } from "../../helpers/feedback/feedback.helper";
import styled from "styled-components";
import {
  gtmSearchCUIT,
  gtmSearchScreen,
} from "../../setup/tagManager/gtmEvents";
import { postCreditRatingService } from "../../services/creditRating/creditRating.service";

const Container = styled.div`
  max-width: 460px;
  margin-top: 32px;
`;
const Search = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.sharedReducer);

  const [searchClient, setSearchClient] = useState("");
  const [error, setError] = useState(false);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (error) setError(false);
    setSearchClient(e.target.value);
  };

  const handleError = (code: string, body: string, header: string) => {
    dispatch(
      setFeedback({
        isError: true,
        code,
        header,
        body,
      })
    );
    navigate("/feedback");
  };

  const handleClientData = async (cuit: string) => {
    try {
      dispatch(setSearchTaxPayerId(cuit));
      dispatch(setIsLoading(true));

      /*
       TODO: VER SI SE VA A NECESITAR EL LLAMADO A CUSTOMER
       
      let resClient = await getDataBaseClient(cuit);

      if (resClient.error) {
        const { body, code, header } = errorCleaner(resClient.error);
        handleError(code, header, body)
        return;
      }
 */
      let resCreditRating = await postCreditRatingService({
        producerTaxPayerId: cuit,
      });

      if (resCreditRating.error) {
        const { body, code, header } = errorCleaner(resCreditRating.error);
        handleError(code, body, header);
        dispatch(setIsANewCustomer(false));
        return;
      }
      const customer = resCreditRating.data.customer;
      const lineOfCredits = resCreditRating.data.lineOfCredits;

      dispatch(setIsANewCustomer(customer.isANewCustomer || false));

      dispatch(
        setCurrentEvaluationCustomer({
          taxPayerId: cuit,
          customerId: customer.id,
          personType: customer.personTypeCode,
          businessName: customer.businessName,
          email: "",
        })
      );
      dispatch(setEmails(customer.emails));
      dispatch(setCurrentEvaluationAvailableId(resCreditRating.data.id));
      dispatch(setAvailableAmount(lineOfCredits.creditAmountARS));

      if (!customer.isANewCustomer) {
        const maxAssumableRisks = lineOfCredits.maxAssumableRisksARS;

        const resControls = await postPreviousLoanControlsService({
          number: customer.id,
          maxAssumableRisks,
        });

        if (resControls.error) {
          const { body, code, header } = errorCleaner(resControls.error);
          dispatch(
            setFeedback({
              isError: true,
              code: code,
              header: header,
              body: body,
            })
          );
          navigate("/feedback");
          return;
        }
      }

      navigate("/amount");
      dispatch(setCurrentStep(1));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleSearch = async () => {
    if (searchClient.length < 13) return setError(true);
    const searchCuit = searchClient.split("-").join("");
    gtmSearchCUIT(searchCuit);
    handleClientData(searchCuit);
  };

  useEffect(() => {
    gtmSearchScreen();
    dispatch(setShowStepper(true));
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <StyledTextColor size="titleL">Buscá a tu cliente</StyledTextColor>
          <Container>
            <InputCuit
              handleOnChange={handleOnChange}
              searchClient={searchClient}
              error={error}
            />
            <Spacer height={40} />
            <Button size="medium" onClick={handleSearch}>
              Buscar
            </Button>
          </Container>
        </>
      )}
    </>
  );
};

export default Search;
