import { useEffect } from "react";

import { getOffers } from "../services/offers/offers.service";
import { setHistory } from "../redux/features/evaluationsSlice";
import { setIsLoading } from "../redux/features/sharedSlice";
import { useAppDispatch } from "../redux/hooks";

export const useLatestEvaluations = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getLatestEvaluations = async () => {
      dispatch(setIsLoading(true));
      try {
        const response = await getOffers();
        dispatch(setHistory(response.data.evaluations));
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    getLatestEvaluations();
  }, [dispatch]);
};
