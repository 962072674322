import styled, { ThemeContext } from "styled-components";
import { Box, Grid } from "@flame-ui/core/layout";

import background from "../../../src/assets/images/Background.png";
import LoginIllustration from "../../components/login/LoginIllustration";
import LoginForm from "../../components/login/LoginForm";
import { useAppSelector } from "../../redux/hooks";
import { LoginFeedback } from "../../components/login/LoginFeedback";
import ResetPassword from "../../components/login/ResetPassword";
import RecoverPassword from "../../components/login/RecoverPassword";
import { useContext } from "react";
import { Spacer } from "../../shared/components/spacer/Spacer";
import { StyledTextColor } from "../../components/SgrStyledComponents";

const Container = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: left bottom;

  @media (max-width: 768px) {
    background-image: none;
  }
`;

const Wrapper = styled(Box)`
  width: 570px;
  justify-self: flex-end;
  background-color: #fff;
  box-shadow: -12px 0px 22px 0px rgba(30, 31, 33, 0.3);
  -webkit-box-shadow: -12px 0px 22px 0px rgba(30, 31, 33, 0.3);
  -moz-box-shadow: -12px 0px 22px 0px rgba(30, 31, 33, 0.3);
  z-index: 3;
  height: 100vh;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HeaderContainer = styled(Box)`
  margin: 72px 12% 0;
  text-align: center;
`;

const FormContainer = styled(Box)`
  margin: 0 12%;
`;

export const LoginPage = () => {
  const gridColsDesktop = "65% 35%";
  const gridColsMobile = "100%";

  const { feedback, showResetPassword, showRecoverPassword } = useAppSelector(
    (state) => state.authReducer
  );

  const theme = useContext(ThemeContext);

  const returnForm = () => {
    if (feedback) {
      return <LoginFeedback />;
    }
    if (showResetPassword) {
      return <ResetPassword />;
    }
    if (showRecoverPassword) {
      return <RecoverPassword />;
    }
    return <LoginForm />;
  };

  const returnTitle = () => {
    if (showResetPassword) {
      return "Cambiá tu clave";
    }
    if (showRecoverPassword) {
      return "Recuperá tu usuario y clave";
    }
    return "Ingresá tus datos para operar";
  };

  const returnSubtitle = () => {
    if (showResetPassword) {
      return "Por seguridad, te pedimos que configures una nueva clave para ingresar.";
    }
    if (showRecoverPassword) {
      return "Ingresá tu email corporativo para que podamos enviarte tu usuario y una clave temporal.";
    }
    return "Si es <strong>la primera vez que ingresás</strong>, usá el usuario y la clave que recibiste por email.";
  };

  return (
    <Container>
      <Grid
        gridTemplateCols={gridColsMobile}
        md={{ gridTemplateCols: gridColsDesktop }}
        gridRows={1}
      >
        <LoginIllustration />
        <Wrapper>
          {!feedback && (
            <HeaderContainer>
              <StyledTextColor
                color={theme.colors.neutral["09"]}
                size="titleM"
                dangerouslySetInnerHTML={{ __html: returnTitle() }}
                data-testid="header"
              />
              <StyledTextColor
                color={theme.colors.neutral["09"]}
                size="paragraphM"
                dangerouslySetInnerHTML={{ __html: returnSubtitle() }}
              />
            </HeaderContainer>
          )}
          <Spacer height={24} />
          <FormContainer>{returnForm()}</FormContainer>
        </Wrapper>
      </Grid>
    </Container>
  );
};
