import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  EvaluationCustomer,
  Evaluation,
  Offer,
} from "../../interfaces/offers.interface";

type EvaluationsState = {
  history: Offer[];
  currentEvaluation: Evaluation;
  emails: string[];
  availableAmount: number;
};

const initialState: EvaluationsState = {
  history: [],
  currentEvaluation: {
    amount: "",
    customer: {
      taxPayerId: "",
      customerId: "",
      riskCommitteeType: "",
      personType: "",
      businessName: "",
      email: "",
    },
    loanTerms: {
      productCode: "39",
      subProductCode: "0005",
    },
    additionalData: {
      partner: {
        taxPayerId: "",
        customerId: "",
        businessName: "",
      },
      available: {
        id: "",
      },
      user: {
        email: "",
        role: "",
      },
    },
    isANewCustomer: false,
  },
  emails: [],
  availableAmount: 0,
};

export const evaluationsSlice = createSlice({
  name: "evaluations",
  initialState,
  reducers: {
    setHistory: (state, action: PayloadAction<Offer[]>) => {
      state.history = action.payload;
    },
    setCurrentEvaluationCustomer: (
      state,
      action: PayloadAction<EvaluationCustomer>
    ) => {
      state.currentEvaluation.customer = action.payload;
    },
    setCurrentEvaluationAvailableId: (state, action: PayloadAction<string>) => {
      state.currentEvaluation.additionalData.available.id = action.payload;
    },
    setEmails: (state, action: PayloadAction<string[]>) => {
      state.emails = action.payload;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.currentEvaluation.amount = action.payload;
    },
    setAvailableAmount: (state, action: PayloadAction<number>) => {
      state.availableAmount = action.payload;
    },
    setEmailCustomer: (state, action: PayloadAction<string>) => {
      state.currentEvaluation.customer.email = action.payload;
    },
    setCurrentEvaluationPartner: (state, action: PayloadAction<any>) => {
      state.currentEvaluation.additionalData.partner = action.payload;
    },
    setIsANewCustomer: (state, action: PayloadAction<boolean>) => {
      state.currentEvaluation.isANewCustomer = action.payload;
    },
    setCurrentEvaluationEmail: (state, action: PayloadAction<any>) => {
      state.currentEvaluation.additionalData.user.email = action.payload;
    },
    setCurrentEvaluationRole: (state, action: PayloadAction<any>) => {
      state.currentEvaluation.additionalData.user.role = action.payload;
    },
  },
});

export const {
  setHistory,
  setCurrentEvaluationCustomer,
  setCurrentEvaluationAvailableId,
  setEmails,
  setEmailCustomer,
  setAmount,
  setAvailableAmount,
  setCurrentEvaluationPartner,
  setIsANewCustomer,
  setCurrentEvaluationEmail,
  setCurrentEvaluationRole,
} = evaluationsSlice.actions;

export default evaluationsSlice.reducer;
