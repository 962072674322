import { createSlice } from "@reduxjs/toolkit";

type AuthState = {
  openModalRefreshToken: boolean;
  isLoggedIn: boolean;
  timerIdToRefresh: any;
  showResetPassword: boolean;
  showRecoverPassword: boolean;
  recoverEmail: any;
  feedback: any;
};

const initialState: AuthState = {
  openModalRefreshToken: false,
  isLoggedIn: false,
  timerIdToRefresh: [],
  showResetPassword: false,
  showRecoverPassword: false,
  recoverEmail: null,
  feedback: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setOpenModalRefreshToken: (state, action) => {
      state.openModalRefreshToken = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setTimerIdToRefresh: (state, action) => {
      state.timerIdToRefresh = action.payload;
    },
    setShowResetPassword: (state, action) => {
      state.showResetPassword = action.payload;
    },
    setShowRecoverPassword: (state, action) => {
      state.showRecoverPassword = action.payload;
    },
    setRecoverEmail: (state, action) => {
      state.recoverEmail = action.payload;
    },
    setFeedback: (state, action) => {
      state.feedback = action.payload;
    },
  },
});

export const {
  setOpenModalRefreshToken,
  setIsLoggedIn,
  setTimerIdToRefresh,
  setShowResetPassword,
  setShowRecoverPassword,
  setRecoverEmail,
  setFeedback,
} = authSlice.actions;

export default authSlice.reducer;
