import { useContext, useEffect } from "react";
import NavigateButtons from "../../components/evaluation/navigateButtons/NavigateButtons";
import { setCurrentStep, setNextPage } from "../../redux/features/sharedSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled, { ThemeContext } from "styled-components";
import { StyledTextColor } from "../../components/SgrStyledComponents";
import { Box } from "@flame-ui/core";
import { Spacer } from "../../shared/components/spacer/Spacer";
import SelectEmails from "../../components/evaluation/email/SelectEmails";
import InputEmail from "../../components/evaluation/email/InputEmail";
import { gtmEmailScreen, gtmSetAmount } from "../../setup/tagManager/gtmEvents";

const Container = styled.div`
  max-width: 460px;
  margin-top: 32px;
`;

const Email = () => {
  const theme = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const { showInputEmail } = useAppSelector((state) => state.sharedReducer);
  const {
    emails,
    availableAmount,
    currentEvaluation: { amount, customer, isANewCustomer },
  } = useAppSelector((state) => state.evaluationsReducer);
  const hasEmails = emails?.length > 0;

  useEffect(() => {
    gtmSetAmount(customer.taxPayerId, amount, availableAmount, isANewCustomer);
    gtmEmailScreen(customer.taxPayerId, isANewCustomer);
    dispatch(setNextPage("/summary"));
    dispatch(setCurrentStep(2));
  }, [dispatch, customer.taxPayerId, amount, availableAmount, isANewCustomer]);

  return (
    <>
      <StyledTextColor size="titleL">
        {hasEmails
          ? "Seleccioná el email de tu cliente"
          : "Ingresá el email de tu cliente"}
      </StyledTextColor>
      <StyledTextColor size="paragraphL" color={theme.colors.neutral["08"]}>
        Lo usaremos para enviarle esta evaluación.
      </StyledTextColor>
      <Container>
        <Box col="1 / span 4">
          {hasEmails && <SelectEmails />}
          {(showInputEmail || !hasEmails) && <InputEmail />}
          <Spacer height={40} />
          <NavigateButtons />
        </Box>
      </Container>
    </>
  );
};

export default Email;
