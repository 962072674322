import { useCallback, useEffect, useRef } from "react";

import Modal from "@flame-ui/core/modal";
import Button from "@flame-ui/core/button";
import Typography from "@flame-ui/core/typography";

import { refreshSession } from "../../services/login/login.service";
import { logoutHelper, refreshHelper } from "../../helpers/login/login.helper";
import {
  setIsLoggedIn,
  setOpenModalRefreshToken,
} from "../../redux/features/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

const UserActiveScreen = () => {
  let toClearTimeOut = false;
  let timer: any = useRef();

  const dispatch = useAppDispatch();
  const { openModalRefreshToken, timerIdToRefresh } = useAppSelector(
    (state) => state.authReducer
  );

  const toSetTimeOutModal = useCallback(() => {
    if (timer.current === undefined)
      timer.current = setTimeout(async () => {
        if (!toClearTimeOut) {
          dispatch(setOpenModalRefreshToken(false));
          logoutHelper(timerIdToRefresh);
          dispatch(setIsLoggedIn(false));
        }
      }, 60 * 1000);
  }, [dispatch, timerIdToRefresh, toClearTimeOut]);

  useEffect(() => {
    toSetTimeOutModal();
    return clearTimeout(timer);
  }, [toSetTimeOutModal]);

  const handleOnClose = () => {
    dispatch(setOpenModalRefreshToken(false));
    logoutHelper(timerIdToRefresh);
    dispatch(setIsLoggedIn(false));
  };

  const handleRefresh = async () => {
    // TODO: No debería ser null. Contemplar error en caso que lo sea. Luego ajustar tests considerando también errores del servicio
    const tokenString = sessionStorage.getItem("token");
    const { refreshToken } = tokenString
      ? JSON.parse(tokenString)
      : { refreshToken: null };

    const bodyRefresh = {
      refreshToken,
    };

    const responseRefreshUser = await refreshSession(bodyRefresh);
    sessionStorage.setItem("token", JSON.stringify(responseRefreshUser.data));

    toClearTimeOut = true;

    refreshHelper(dispatch, responseRefreshUser.data, timerIdToRefresh);
    dispatch(setOpenModalRefreshToken(false));
  };

  return (
    <Modal
      title="En 60 segundos vamos a cerrar la sesión"
      primaryButton={
        <Button onClick={() => handleRefresh()} data-testid="keepSessionButton">
          Mantener sesión
        </Button>
      }
      secondaryButton={
        <Button
          onClick={() => handleOnClose()}
          variant="tertiary"
          data-testid="closeSessionButton"
        >
          Cerrar sesión
        </Button>
      }
      show={openModalRefreshToken}
      onClose={() => dispatch(setOpenModalRefreshToken(false))}
    >
      <Typography>
        Lo hacemos para resguardar la seguridad de tu cuenta.
      </Typography>
    </Modal>
  );
};
export default UserActiveScreen;
