import CheckmarkInACircle from "@flame-ui/icons/src/checkmark-in-a-circle";
import Error from "@flame-ui/icons/src/error";
import styled from "styled-components";
import { Box, Container, Grid } from "@flame-ui/core/layout";
import Button from "@flame-ui/core/button";
import Typography from "@flame-ui/core/typography";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import {
  gtmFeedbackError,
  gtmFeedbackScreen,
} from "../../setup/tagManager/gtmEvents";

const StyledContainer = styled(Container)`
  min-height: 100vh;
`;

const StyledTypography = styled(Typography)`
  text-align: center;
`;
const StyledBoxButton = styled(Box)`
  display: flex;
  gap: 20px;
  margin: 10px;
`;
export const FeedbackScreen = () => {
  const navigate = useNavigate();

  const { feedback, searchTaxPayerId } = useAppSelector(
    (state) => state.sharedReducer
  );
  const {
    currentEvaluation: { customer, isANewCustomer },
  } = useAppSelector((state) => state.evaluationsReducer);
  console.log("feedback", feedback);

  const handleOnClick = () => {
    navigate("/dashboard");
  };

  const switcher = () => {
    if (!feedback.isError) {
      gtmFeedbackScreen(customer.taxPayerId, isANewCustomer);
      return <CheckmarkInACircle color="#02C06D" size={4} />;
    } else {
      gtmFeedbackError(searchTaxPayerId, feedback, isANewCustomer);
      return <Error color="#FB6400" size={4} />;
    }
  };

  return (
    <StyledContainer>
      <Box
        alignSelf="center"
        justifySelf="center"
        col="1 / span 4"
        md={{ col: "1 / span 8" }}
        lg={{ col: "1 / span 12" }}
      >
        <Grid justifyItems="center" gridRows={3} gridCols={1} gap="0 20px">
          <Box>
            <Grid justifyItems="center" gridRows={1} gridCols={1}>
              <Box>{switcher()}</Box>
            </Grid>
          </Box>

          <Box>
            <Grid justifyItems="center" gridRows={2} gridCols={1} gap="10px 0">
              <StyledTypography size="titleL">
                {feedback.header}
              </StyledTypography>
              <StyledTypography
                size="paragraphL"
                dangerouslySetInnerHTML={{ __html: feedback.body }}
              />
            </Grid>
          </Box>
          <Box>
            <Grid justifyItems="center" gridRows={2} gridCols={1} gap="10px 0">
              <StyledBoxButton>
                <Button
                  variant={"primary"}
                  onClick={handleOnClick}
                  data-testid={`btn-start`}
                >
                  Ir a inicio
                </Button>
              </StyledBoxButton>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </StyledContainer>
  );
};

export default FeedbackScreen;
