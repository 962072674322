import { useContext } from "react";

import styled, { ThemeContext } from "styled-components";
import InvestmentFund from "@flame-ui/icons/src/investment-fund";
import { StyledTextColor } from "../SgrStyledComponents";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 321px;
  margin: 32px auto;
`;

const EmptyState = () => {
  const theme = useContext(ThemeContext);
  return (
    <Container>
      <InvestmentFund color={theme.colors.accent["01"]} size={4} />
      <StyledTextColor size="titleM">
        No hay información para mostrar
      </StyledTextColor>
      <StyledTextColor color={theme.colors.neutral["09"]}>
        Acá vas a encontrar todas las evaluaciones crediticias realizadas.
      </StyledTextColor>
    </Container>
  );
};

export default EmptyState;
