import ReactDOM from "react-dom";
import AppProviders from "./AppProviders";
import reportWebVitals from "./reportWebVitals";
import "../src/api/AxiosConfig";
import { gtmInitialize } from "./setup/tagManager/gtmConfiguration";

gtmInitialize();

ReactDOM.render(<AppProviders />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
