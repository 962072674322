import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";

const LoginRoute = () => {
  const { isLoggedIn } = useAppSelector((state) => state.authReducer);

  return isLoggedIn ? <Navigate to={"/dashboard"} replace /> : <Outlet />;
};

export default LoginRoute;
