import styled from "styled-components";

import brand from "../../../src/assets/images/Brand.png";
import { StyledTextColor } from "../SgrStyledComponents";

const Container = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const ContainerContent = styled.div`
  margin-left: 5%;
  position: relative;
  top: 5%;
`;

const LoginIllustration = () => {
  return (
    <Container>
      <ContainerContent>
        <img src={brand} alt="logo santander sgr"></img>
        <StyledTextColor size="titleXl">
          ¡Hola! Te damos la bienvenida
        </StyledTextColor>
      </ContainerContent>
    </Container>
  );
};

export default LoginIllustration;
