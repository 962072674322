import Axios from "axios";

Axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

let authTokens;
let taxPayerId;

Axios.interceptors.request.use((request) => requestHandler(request));

Axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export const requestHandler = (request) => {
  authTokens = JSON.parse(sessionStorage.getItem("token"));
  taxPayerId = JSON.parse(sessionStorage.getItem("taxPayerId"));
  if (authTokens) {
    request.headers[
      "Authorization"
    ] = `${authTokens.tokenType}${authTokens.accessToken}`;
    if (!request.headers["x-san-taxpayerid"])
      request.headers["x-san-taxpayerid"] = `${taxPayerId}`;
  }

  return request;
};

// Interceptor de response ERROR.
export const errorHandler = (error) => {
  let errorMessage = "Error de conexion";

  if (error.response) {
    switch (error.response.status.toString()) {
      case "401": {
        // Forbbiden
        sessionStorage.clear();
        window.location.assign("/login");
        return Promise.reject(error);
      }

      case "400": {
        console.log("ERROR EN AXIOSCONFIG 400");
        console.log({ error });
        return Promise.reject(error);
      }

      case "403": {
        console.log("ERROR EN AXIOSCONFIG 403");
        console.log({ error });
        return Promise.reject(error);
      }

      case "500": {
        // INTERNAL SERVER ERROR
        console.log("ERROR EN AXIOSCONFIG 500");
        console.log({ error });
        return Promise.reject(error);
      }

      case "503": {
        // TIMEOUT ERROR
        console.log("ERROR EN AXIOSCONFIG 503");
        console.log({ error });
        return Promise.reject(error);
      }

      case "504": {
        // TIMEOUT ERROR
        console.log("ERROR EN AXIOSCONFIG 504");
        console.log({ error });
        return Promise.reject(error);
      }

      default: {
        console.log(
          "paso por aca por que es un error distinto a todos los errrores"
        );
        sessionStorage.clear();
        window.location.assign("/login");
      }
    }
  } else {
    const elseError = {
      isError: true,
    };
    return Promise.reject(elseError);
  }

  return {
    // After all, return the object
    error: {
      code: error.response?.status.toString(),
      errorMessage: errorMessage,
    },
    data: undefined,
  };
};
// Interceptor de response OK.
export const successHandler = (response) => ({
  // Response
  data: response.data,
  error: undefined,
});
