import AppRouter from "./routers/AppRouter";
import UserActiveScreen from "./components/login/UserActiveScreen";
import { useAppSelector } from "./redux/hooks";
import "./styles.css";

const App = () => {
  const { openModalRefreshToken } = useAppSelector(
    (state) => state.authReducer
  );
  return (
    <>
      <AppRouter />
      {openModalRefreshToken && <UserActiveScreen />}
    </>
  );
};

export default App;
