import forge from "node-forge";

export function encryptData(password: string) {
  let publicKey = forge.pki.publicKeyFromPem(
    process.env.REACT_APP_RSA_PUBLIC_KEY!
  );
  let encrypted = publicKey.encrypt(password, "RSA-OAEP");
  let encryptedBase64 = forge.util.encode64(encrypted);
  return encryptedBase64.toString();
}
