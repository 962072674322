/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import InputRadio from "@flame-ui/core/inputRadio";
import FieldsetGroup from "@flame-ui/core/fieldsetGroup";
import { useEffect, useState } from "react";
import {
  setIsDisabledButton,
  setShowInputEmail,
} from "../../../redux/features/sharedSlice";
import { setEmailCustomer } from "../../../redux/features/evaluationsSlice";

type IEmail = {
  id: string;
  isOther: boolean;
}[];

const SelectEmails = () => {
  const dispatch = useAppDispatch();
  const { emails } = useAppSelector((state) => state.evaluationsReducer);

  const [emailSelected, setEmailSelected] = useState("");
  const arrEmails = emails.map((email) => {
    return { id: email, isOther: false };
  });

  const listEmails = [
    ...arrEmails,
    { id: "Ingresar otro email", isOther: true },
  ];
  const handleInputRadio = (e: any) => {
    setEmailSelected(e.target.value);
    const isOtherEmail = evaluateEmail(e.target.value);
    dispatch(setShowInputEmail(isOtherEmail));
    dispatch(setIsDisabledButton(isOtherEmail));
    dispatch(setEmailCustomer(e.target.value));
  };

  const evaluateEmail = (emailValue: string) => {
    const filter: IEmail = listEmails.filter(
      (email) => email.id === emailValue
    );
    return filter[0].isOther;
  };

  useEffect(() => {
    const initialEmail = listEmails[0].id;
    setEmailSelected(initialEmail);
    dispatch(setEmailCustomer(initialEmail));
    dispatch(setShowInputEmail(false));
    dispatch(setIsDisabledButton(false));
  }, []);

  return (
    <FieldsetGroup labelGroup="" name="emailsGroup" display="block">
      {listEmails.map((email, i) => (
        <InputRadio
          data-testid={`${email.id}`}
          key={i}
          name="email"
          value={email.id}
          onChange={handleInputRadio}
          checked={emailSelected === email.id}
        >
          {email.id}
        </InputRadio>
      ))}
    </FieldsetGroup>
  );
};
export default SelectEmails;
