// import React from 'react';
import { BreadcrumbContainer, BreadcrumbItem } from "@flame-ui/core/breadcrumb";
import { useNavigate } from "react-router-dom";

const BreadCrumb = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      title: "Inicio",
      href: "/dashboard",
    },
    {
      title: "Evaluación Crediticia",
      href: "/search",
    },
  ];
  const handleClick = (e: any, path: string) => {
    e.preventDefault();
    navigate(path);
  };
  return (
    <BreadcrumbContainer>
      {breadcrumbs.map((item, i) => (
        <BreadcrumbItem
          href={item.href}
          title={item.title}
          key={i}
          onClick={(e) => handleClick(e, item.href)}
        />
      ))}
    </BreadcrumbContainer>
  );
};

export default BreadCrumb;
