import axios from "axios";
import {
  IReqCerditRating,
  IResCerditRating,
} from "../../interfaces/creditRating.interface";

export const postCreditRatingService = (
  body: IReqCerditRating
): Promise<IResCerditRating> =>
  axios
    .post("/customers/pj-loan-credit-rating", body)
    .then((res) => res.data)
    .catch((error) => ({ error }));
