import styled from "styled-components";
import { Box } from "@flame-ui/core/layout";

export const ButtonsContainer = styled(Box)`
  display: flex;
  width: 100%;
  gap: 16px;
  flex-direction: row-reverse;
  justify-content: center;

  > .expand {
    flex: 1;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;

    > .expand {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;
    }
  }
`;
