import { Grid } from "@flame-ui/core";
import styled from "styled-components";
import Button from "@flame-ui/core/button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setCurrentStep,
  setIsLoading,
} from "../../../redux/features/sharedSlice";
import { pageToStep } from "../../../constants/constants";
import { setFeedback } from "../../../redux/features/sharedSlice";
import { createOffer } from "../../../services/offers/offers.service";
import { errorCleaner } from "../../../helpers/feedback/feedback.helper";

const StyledSendButton = styled(Button)`
  width: max-content;
`;

const SummaryButtons = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { currentStep } = useAppSelector((state) => state.sharedReducer);
  const { currentEvaluation } = useAppSelector(
    (state) => state.evaluationsReducer
  );

  const getBodyMessage = (): string => {
    if (currentEvaluation.isANewCustomer)
      return "Para continuar con la solicitud de préstamo, <strong>tu cliente tiene que abrir una Cuenta Pymes y Empresas en Santander.</strong><br>Le enviamos un email con los pasos a seguir.";

    return "Un oficial de cuenta te contactará para avanzar con la emisión del aval.";
  };

  const handleBack = () => {
    navigate(pageToStep[currentStep - 1]);
    dispatch(setCurrentStep(currentStep - 1));
  };

  const handleSendEvaluation = async () => {
    dispatch(setIsLoading(true));
    const resEvaluation = await createOffer(currentEvaluation);
    if (!resEvaluation?.error) {
      dispatch(
        setFeedback({
          isError: false,
          code: "success",
          header: "Enviaste la evaluación con éxito",
          body: getBodyMessage(),
        })
      );
    } else {
      const { body, code, header } = errorCleaner(resEvaluation.error);
      dispatch(
        setFeedback({
          isError: true,
          code,
          header,
          body,
        })
      );
    }
    dispatch(setIsLoading(false));
    navigate("/feedback");
  };

  return (
    <Grid gridCols={12} gridRows={1} gap="20px">
      <Button
        size="medium"
        variant="secondary"
        onClick={handleBack}
        data-testid="btnBack"
      >
        Atrás
      </Button>
      <StyledSendButton
        size="medium"
        onClick={handleSendEvaluation}
        data-testid="btnSendEvaluation"
      >
        Enviar evaluación
      </StyledSendButton>
    </Grid>
  );
};

export default SummaryButtons;
